import './index.css'
interface IAvatarProps {
    size?: number,
    content?: string
    src?: string
}
export function Avatar(props: IAvatarProps) {
    const { size, content, src } = props
    return (
        <span content=""
            className="avatar"
            style={{ width: `${size}px`, height: `${size}px`, lineHeight: `${size}px`, background: `rgb(41, 121, 255)` }}>
            {
                src
                    ? <img src={src} />
                    : <span className="avatar-string" style={{ lineHeight: `${size}px` }}>
                        {content}
                    </span>
            }
        </span>
    )
}
