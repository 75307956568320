import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { locationIcon, feeService } from '../../utils/icon';
import * as Utils from '../../utils/env'
import { areaIcon, widthHeigh, moneyBagIcon, moneyIcon, dateTimeIcon } from '../../utils/icon'
import { IProduct } from 'interfaces';
import moment from 'moment';
import { EStatusProduct } from 'helpers/enums';
import {
  TypeProducts,
  DirectionTypes,
} from 'helpers/config';
import { FiHeart } from 'react-icons/fi';
import { Avatar } from 'components'

interface IProps {
  product: IProduct;
  onItemClick?: () => void;
  page: number,
  setDataToLocalStorage?: Function
  setProductSaved?: Function
  query?: any
}

export const Card = (props: IProps) => {
  const { product, onItemClick, page, setDataToLocalStorage, query, setProductSaved } = props;
  const defaultImage = 'https://techland-test.hn.ss.bfcplatform.vn/master/logo/apple-touch-icon.png'

  const renderLocation = () => {
    return (
      <div className="d-flex align-items-center" style={{ marginTop: '4px', fontSize: '14px' }}>
        <span className='d-flex align-items-center'> {locationIcon(24, 24)} </span>
        &nbsp;:&nbsp;
        <span className='product-location'>
          <a style={{ color: '#2979ff' }} onClick={e => e.stopPropagation()} target='_blank'
            href={'https://www.google.com/maps/dir/?api=1&destination=' + (product.address?.street + ", " + product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName)}>{product.address?.cityName ? product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName : 'Hồ Chí Minh, Việt Nam'}
          </a>
        </span>
      </div>
    );
  };

  const renderLandArea = () => {
    if (!product.acreage)
      return <></>
    return <div className='row no-gutters d-flex items-center product-acreage'>
      <div className='col-6 d-flex align-items-center'>
        <span className='icon'>{areaIcon()}</span>
        <span className='product-acreage'>&nbsp;: {product.acreage} m² </span>
      </div>
      <div className='col-6 d-flex align-items-center'>
        <span className='d-flex '>{widthHeigh()} &nbsp;:</span> &nbsp;
        <span className='product-acreage'>{product.width}x{product.height} m</span>
      </div>
    </div>
  };

  const renderPrice = () => {
    if (!product.price)
      return <></>
    return <div className='row no-gutters product-price d-flex items-center'
      style={{ marginTop: '4px' }}>
      <div className='col-6 d-flex align-items-center'>
        <span className='icon'>{moneyIcon(24, 24)}</span>
        {product.priceSale
          ? <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
            &nbsp;:&nbsp;
            <span className='' style={{ textDecoration: 'line-through' }}>{Utils.convertMilionToBilion(product.price, false)}</span>
            &nbsp;
            <span className=''>{Utils.convertMilionToBilion(product.priceSale, true)}</span>
          </span>
          : <span className=''> &nbsp;: {Utils.convertMilionToBilion(product.price, true)}</span>
        }
      </div>
      <div className='col-6 d-flex'>
        <span className='d-flex '>{feeService()}</span>
        {
          (product.fee || product.feePercent)
            ? <span className=''>
              &nbsp;:&nbsp;{product.fee ? Utils.convertMilionToBilion(product.fee, true) : product.feePercent + "%"}
            </span>
            : <span className=''>{"TL"}</span>
        }
      </div>
    </div>
  }

  if (!product) return <></>;

  const renderInfo = () => {
    return <div>
      <div className='product-price d-flex flex-column' style={{ fontSize: "14px" }} >
        {product.directionType != null ? <span className=''>{"Hướng: " + (DirectionTypes.find(a => a.value == product.directionType)?.label)}</span> : <span></span>}
        {product.typeProduct != null ? <span className=''>{"Loại: " + (TypeProducts.find(a => a.value == product.typeProduct)?.label)}</span> : <span></span>}

      </div>
    </div>
  }

  const _renderStatusTag = () => {
    const diffDays = moment().diff(product?.updatedAt, 'days');
    if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
      return <div className="status">Mới</div>;
    }
    if (product.status > EStatusProduct.SALE) {
      return <div className="sold" >Đã bán</div>;
    }
    return null;
  };

  const _getStatusTag = () => {
    const diffDays = moment().diff(product?.updatedAt, 'days');
    if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
      return 1;
    }
    if (product.status > EStatusProduct.SALE) {
      return 2;
    }
    return null;
  };

  const _renderDateTag = () => {
    var status = _getStatusTag();
    return <div className='row no-gutters d-flex items-center' style={{ marginTop: '4px' }}>
      <div className='d-flex align-items-center'>
        <span className='icon'>{dateTimeIcon(24, 22)}</span>
        <span className=''>&nbsp;:&nbsp;
          {Utils.getDateShort(product.updatedAt, "DD-MM-YYYY HH:mm")}
        </span>
      </div>
    </div>
  };

  return (
    < Link to={{
      pathname: `/admin/product/${product.id}`
    }}
      state={{ id: product.id, page: page, query: query }}
      style={{ textDecoration: 'none' }}
      onClick={() => setDataToLocalStorage()}
    >
      <div className={`card-wrapper restore-${product.id} bg-white  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
        {_renderStatusTag()}
        <div className="content-wrapper">
          <div className='d-flex flex-column justify-content-between gy-3'>
            <div className='img-wrapper'>
              <img loading='lazy' className='image' src={product.produtMediaFiles?.length > 0 ? product.produtMediaFiles[0]?.url : defaultImage} alt="" />
            </div>
            <div className='' style={{ padding: '12px 16px' }}>
              <div style={{ color: '#212121' }}>
                <div className="d-flex items-center justify-content-between gap-2 rounded-lg" >
                  <div className='d-flex'>
                    <Avatar size={36} content='name' src={product.employee?.photo} />
                    <span className="d-flex items-center product-created-by ml-3">
                      {product.employee?.name}
                    </span>
                  </div>
                  <div className={`icon-save-product ${product.isSaved ? 'is-saved' : ''}`}
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); setProductSaved(product.id); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" display="block" id="Heart">
                      <path d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95 22 5.216 19.761 3 17 3s-5 3-5 3-2.239-3-5-3z" />
                    </svg>
                  </div>
                </div>

                <div className="product-name dark:text-white">
                  {product.name}
                </div>

                {renderInfo()}
                {(product.property != null) ? <div className='product-price d-flex items-center' style={{ fontSize: '14px' }}><span className=''>{"Hiện trạng: " + product.property}</span> </div> : <span></span>}
              </div>
              <hr />
              <div className='item-info' style={{ fontSize: '14px', color: '#2979ff' }}>
                {renderLandArea()}
                {renderPrice()}
                {renderLocation()}
                {_renderDateTag()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
