import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from "js-cookie";
declare module 'axios' {
  export interface AxiosRequestConfig {
    token?: string;
    isFormData?: boolean;
  }
}

// const API_ENDPOINT = 'https://api.namdaivu.com.vn/api';
var isProduction = window.location.host == "namdaivu.com.vn" ? true : false;
const API_ENDPOINT = isProduction == true
  ? "https://api.namdaivu.com.vn/api"
  // : "http://localhost:5001/api"
  : "https://test.namdaivu.com.vn/api";
const service = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 30000,
});


// const tokenTemp = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgyMTU0N0E2RTNEODg5M0I4NjdDMUQzMDZDMTYxQjA3QTNDNTJGQTIiLCJ4NXQiOiJnaFZIcHVQWWlUdUdmQjB3YkJZYkI2UEZMNkkiLCJ0eXAiOiJhdCtqd3QifQ.eyJpc3MiOiJodHRwczovL2FjYy5uYW1kYWl2dS5jb20udm4vIiwiZXhwIjoxNzEyMTM3NzI5LCJpYXQiOjE3MTEyNzM3MjksImp0aSI6IjFkMjU0ZjRmLTg5MmEtNGVjYi1iNTg0LTgyOTdiMTM5NjFhYSIsInN1YiI6IjQiLCJuYW1lIjoiMDM1NTcyNzU0NSIsInByZWZlcnJlZF91c2VybmFtZSI6IjAzNTU3Mjc1NDUiLCJyb2xlIjpbInRsX2FwaS5hZG1pbiIsImFjY19hZG1pbiJdLCJhdWQiOiJ0bF9hcGkiLCJvcmdpZCI6MSwib2lfYXVfaWQiOiIxMSIsIm9pX3Byc3QiOiJ0ZS5zd2FnZ2VyLnRlc3QiLCJjbGllbnRfaWQiOiJ0ZS5zd2FnZ2VyLnRlc3QiLCJzY29wZSI6InRsX2FwaSIsIm9pX3Rrbl9pZCI6IjEwMjUifQ.cGIe2VLCb0iRgnXA8de7WXjI96rWFbBr_7B7B4HAm1DP1gTt0RZk13MoapmvHsyrI96YYUoCFhAXrfNG3uPiaIc9ujXNAzL3DMzQK5C2yp4wfnR56jCTjusg6Sdxq58KIWSIokJFTp8aBtsDxXf5srws7oMalV2OovE28FZSVCq2HYGmxcwkiBV5Ii7_d8pokCxc_fUOMDK-JLZcKhqnDH7A08iD-YgWqGrLq0DcD-MkhyLOLHBQ6CJu9ZLmVyoBdQdVy7PnGYwjGufVSIYRpU3LvHtxn9opToI35oGI1jLULWD4UgbzShAd9XZJgpE73ymvRZiZgsqrUPTFAIXrRA"

service.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    config.data = config.data || [];

    config.params = config.params || {};

    config.headers['Content-Type'] = 'application/json';

    config.headers['Access-Control-Allow-Origin'] = '*';

    const token = JSON.parse(localStorage.getItem('token'));
    config.headers.Authorization = `Bearer ${token}`;

    // if (config.token) {
    //   config.headers.Authorization = `Bearer ${config.token}`;
    // }

    if (config.isFormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
