import React from 'react';
import { useStateContext } from '../../Contexts/ContextProvider';
import avatar from '../../Data/avatar.jpg';
import Cookies from "js-cookie";
import './index.css';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { closeIcon, logoutIcon } from '../../utils/icon'
import { IEmployee } from 'interfaces';

interface IUserProfileProps {
    profile?: IEmployee
}
export const UserProfile = (props: IUserProfileProps) => {
    const { profile } = props
    const { setIsClicked, initialState } = useStateContext();
    console.log('profile: ', profile)
    return (
        <div className="user-profile shadow nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
            <div className="flex justify-between items-center">
                <div className="flex items-center font-semibold dark:text-gray-200">Thông tin tài khoản</div>
                <span onClick={() => setIsClicked(initialState)}>{closeIcon()}</span>
            </div>
            <div className="d-flex flex-column justify-content-center gap-5 items-center mt-6 border-color border-b-1 pb-6">
                <div style={{ width: '100%', position: 'relative' }}>
                    <div style={{ height: '40px', backgroundColor: '#fcae0e' }}>
                    </div>
                    <img
                        className="user-profile-avatar  rounded-full h-20 w-20"
                        src={profile?.photo ?? avatar}
                        alt="user-profile"
                    />
                </div>
                <div className='user-profile-name'>
                    {profile?.name}
                </div>
            </div>
            <div className="flex flex-column border-b-1 border-color p-4  dark:hover:bg-[#42464D]"
                style={{ gap: '1.25rem' }}>
                <div className='d-flex'>
                    <button
                        type="button"
                        style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
                        className=" text-xl rounded-lg p-3 hover:bg-light-gray"
                    >
                        <BsFileEarmarkPerson />
                    </button>
                    <div className='ml-3 bg-light-gray' style={{ padding: '4px 6px' }}>
                        <p className="font-semibold dark:text-gray-200"> Nam Đại Vũ </p>
                        <p className="text-gray-500 font-semibold dark:text-gray-400">  Quản lý   </p>
                        <p className="text-gray-500 font-semibold dark:text-gray-400"> hi@namdaivu.com.vn </p>
                    </div>
                </div>
                <div className='d-flex justify-content-center cursor-pointer'>
                    <a href='/admin/me/profile'
                        className='text-decoration-none'
                        children={'Xem chi tiết hồ sơ'} />
                </div>
            </div>
            <div className="d-flex mt-5" onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                Cookies.remove(".AspNetCore.Cookies");
                //window.location.href = "/";
                var isProduction = window.location.host == "namdaivu.com.vn" ? true : false;
                var hostAcc = isProduction == true ? "https://accounts.namdaivu.com.vn/account/logout" : "https://acc.namdaivu.com.vn/account/logout";
                window.location.href = hostAcc + "?returnUrl=" + window.location.href;
            }}>
                {logoutIcon()}
                <span style={{ marginLeft: '8px', fontSize: '16px' }}>Đăng xuất</span>
            </div>
        </div >

    );
};